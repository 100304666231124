import React, { useEffect, useState } from "react";
import {
  ChakraProvider,
  Spinner,
  Flex,
  theme,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import Nav from "./page/global/TopBar";
import ParamPage from "./page/global/ParamPage";
import SearchPage from "./page/global/SearchPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { fetchDriverInformation } from "./api/api";

export const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [driverInformation, setDriverInformation] = useState<any[]>([]);
  const [usersNote, setUsersNote] = useState<any[]>([]);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("ID");
  const company = queryParams.get("Company");
  const sub = window.location.pathname === "/Search";
  const payload = {
    ID: id || "",
    Company: company || "",
    Process: "Data",
    Password: "AllInToWin2023",
  };

  useEffect(() => {
    console.log("ID or Company changed", id, company);

    if (id && company && !sub) {
      console.log("Fetching driver information");
      setIsLoading(true);
      fetchDriverInformation(payload)
        .then((data) => {
          const { driverinformation, UsersNote } = data;
          setDriverInformation(driverinformation);
          setUsersNote(UsersNote);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching driver information:", error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [id, company, sub]);

  console.log("App component re-rendered");

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/Search" element={<SearchPage />} />
          <Route
            path="*"
            element={
              <>
                {isLoading ? (
                  <Flex
                    height="100vh"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner color="orange.500" size="lg" />
                  </Flex>
                ) : (
                  <>
                    {driverInformation.length === 0 && !sub && (
                      <Alert
                        status="warning"
                        variant="subtle"
                        flexDirection="column"
                        alignItems="center"
                        textAlign="center"
                        maxW="sm"
                        py={6}
                        px={8}
                        borderRadius="md"
                        boxShadow="md"
                        position="absolute"
                        top="px"
                        left="50%"
                        transform="translateX(-50%)"
                      >
                        <AlertIcon boxSize={6} mr={0} />
                        No record found.
                      </Alert>
                    )}
                    {!sub ? (
                      <ParamPage
                        driverinformation={driverInformation}
                        UsersNote={usersNote}
                      />
                    ) : null}
                  </>
                )}
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
