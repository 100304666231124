import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Input,
  VStack,
  List,
  ListItem,
  Center,
  Flex,
  Alert,
  AlertIcon,
  Spinner,
  CSSReset,
  Stack,
  Text,
} from "@chakra-ui/react";

interface SearchComponentProps {
  driverName: string;
  setDriverName: (name: string) => void;
  onSelectSuggestion: (suggestion: Suggestion) => void;
}

interface Suggestion {
  ID: string;
  DriverName: string;
  OrientationLocation: string;
  OrientationDate: string;
  Company: string;
}

interface UserNote {
  Name: string;
  Message: string;
  Time: string;
}

const SearchComponent: React.FC<SearchComponentProps> = ({
  driverName,
  setDriverName,
  onSelectSuggestion,
}) => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const fetchSuggestions = async (searchTerm: string) => {
    try {
      const response = await axios.post(
        "https://prod-63.westus.logic.azure.com:443/workflows/a8b8d71053c547d497a838d42d58b82c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=-fmrAwoilC2Hd0hK7wwLuz_PcDKjUL4SWxwf2KCRpNQ",
        { searchTerm }
      );
      const suggestions: Suggestion[] = response.data;
      setSuggestions(suggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSelectSuggestion = (suggestion: Suggestion) => {
    onSelectSuggestion(suggestion);
  };

  useEffect(() => {
    if (driverName.length > 3) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      setSearchTimeout(
        setTimeout(() => {
          fetchSuggestions(driverName);
        }, 1000)
      );
    } else {
      setSuggestions([]);
    }
  }, [driverName]);

  return (
    <Box
      p={4}
      bg="white"
      rounded="md"
      border="1px"
      borderColor="gray.200"
      boxShadow="md"
      transition="box-shadow 0.5s ease"
      _hover={{
        boxShadow: "lg",
      }}
      marginBottom={10}
    >
      <VStack spacing={4} align="stretch">
        <label htmlFor="search">Search</label>
        <Input
          id="search"
          placeholder="Driver Name"
          value={driverName}
          onChange={(e) => setDriverName(e.target.value)}
        />
        <List>
          {suggestions.map((suggestion) => (
            <ListItem key={suggestion.ID}>
              <button
                onClick={() => handleSelectSuggestion(suggestion)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {suggestion.DriverName} ({suggestion.OrientationLocation} -{" "}
                {suggestion.OrientationDate}) - {suggestion.Company}
              </button>
            </ListItem>
          ))}
        </List>
      </VStack>
    </Box>
  );
};

const NoteTable: React.FC<{ notes: UserNote[] }> = ({ notes }) => {
  if (notes.length === 0) {
    return (
      <Flex justify="center" align="center" height="auto">
        <Box fontSize="20px" color="gray" textAlign="center">
          Begin the search to find historical notes.
        </Box>
      </Flex>
    );
  }

  return (
    <Stack spacing={4}>
      {notes.map((note, index) => (
        <Box
          key={index}
          shadow="md"
          rounded="md"
          p={4}
          borderWidth={1}
          borderColor="gray.200"
        >
          <strong>Name:</strong> {note.Name} <br />
          <strong>Time:</strong> {note.Time} <br />
          <strong>Message:</strong> {note.Message}
        </Box>
      ))}
    </Stack>
  );
};

const SearchPage = () => {
  const [driverName, setDriverName] = useState("");
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<Suggestion | null>(null);
  const [userNotes, setUserNotes] = useState<UserNote[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSelectSuggestion = async (suggestion: Suggestion) => {
    try {
      setIsLoading(true);
      setError(false);

      const response = await axios.post(
        "https://prod-05.westus.logic.azure.com:443/workflows/93c14177ce58477d8401d0c2f77c04c7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=PDOHHk8zyKRv2GD6Xx7ax_SOKl-ygucsUwhm2yDD_9Q",
        { ID: suggestion.ID, Company: suggestion.Company }
      );

      const notes: UserNote[] = response.data;
      setUserNotes(notes);
      setSelectedSuggestion(suggestion);
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const DriverCard = () => {
    if (!selectedSuggestion) {
      return null;
    }

    const { DriverName, OrientationLocation, OrientationDate, Company } =
      selectedSuggestion;

    return (
      <Box
        shadow="md"
        rounded="md"
        p={4}
        borderWidth={1}
        borderColor="gray.200"
        bg="gray.100"
        mt={4}
      >
        <Text fontSize="xl" fontWeight="bold" mb={2}>
          Driver Information
        </Text>
        <Text>
          <strong>Driver Name:</strong> {DriverName}
        </Text>
        <Text>
          <strong>Orientation Location:</strong> {OrientationLocation}
        </Text>
        <Text>
          <strong>Orientation Date:</strong> {OrientationDate}
        </Text>
        <Text>
          <strong>Company:</strong> {Company}
        </Text>
      </Box>
    );
  };

  return (
    <Center py={6}>
      <CSSReset />
      <Box width="100%" maxWidth="1200px">
        <Flex position="relative" align="center" justify="center" mx="auto">
          <Box
            bg="white"
            boxShadow="lg"
            rounded="md"
            overflow="hidden"
            p={{ base: 4, md: 6 }}
            h="85vh"
            maxHeight="80%"
            style={{ overflowY: "auto", margin: "10px" }}
            position="relative"
            width="100%"
          >
            <SearchComponent
              driverName={driverName}
              setDriverName={setDriverName}
              onSelectSuggestion={handleSelectSuggestion}
            />
            {isLoading ? (
              <Flex justify="center" align="center" height="100%">
                <Spinner color="red.500" />
              </Flex>
            ) : error ? (
              <Alert status="warning">
                <AlertIcon />
                We cannot find notes on this driver.
              </Alert>
            ) : selectedSuggestion ? (
              <DriverCard />
            ) : null}
            <NoteTable notes={userNotes} />
          </Box>
        </Flex>
      </Box>
    </Center>
  );
};

export default SearchPage;
