import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";

// Get the root element for rendering the React application
const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");

// Create a root for ReactDOM
const root = ReactDOM.createRoot(container);

// Render the application inside the root
root.render(
  <React.StrictMode>
    <ColorModeScript /> {/* Enable Chakra UI color mode */}
    <App /> {/* Render the main App component */}
  </React.StrictMode>
);
