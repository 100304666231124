import { Menu, MenuButton, MenuList, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import NoteAdding from "./NoteAdding";
import ManagerApprove from "./ManagerApprove";

const DropdownMenu = () => {
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={Button}
            rightIcon={<ChevronDownIcon />}
            size="lg"
            fontSize="1.2rem"
          >
            {isOpen ? "Action" : "Action"}
          </MenuButton>
          <MenuList style={{ fontSize: "1.2rem" }}>
            <NoteAdding />
            <ManagerApprove />
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default DropdownMenu;
