import React from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  Stack,
  useColorMode,
  Image,
  Heading,
  VStack,
  HStack,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";

// Nav component
export default function Nav() {
  const { colorMode } = useColorMode();

  return (
    <>
      <Box
        bg={useColorModeValue("white", "gray.900")}
        px={4}
        boxShadow={
          colorMode === "light"
            ? "0 0 10px rgba(0, 0, 0, 0.5)"
            : "0 0 10px rgba(255, 255, 255, 0.5)"
        }
      >
        <Flex h={100} alignItems={"center"} justifyContent={"space-between"}>
          <Box>
            <HStack>
              <Link to="/search">
                <Image
                  h={90}
                  w={300}
                  src="/hirschbach logo.png"
                  alt="Hirschbach Logo"
                  cursor="pointer"
                />
              </Link>
              <VStack alignItems="flex-start">
                <Heading size="md" fontFamily="Helvetica">
                  Onboarding Management
                </Heading>
              </VStack>
            </HStack>
          </Box>
          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={7}></Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
