import axios from "axios";

// Define the API URL
const API_URL =
  "https://prod-49.westus.logic.azure.com:443/workflows/a91b81f5a921408c8b9ba9f6fd919108/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=DIp18EcWFZYqDSc2l_-KihKRpiyNQaz57SJyNu7t-XQ";

// Define the payload interface
interface Payload {
  ID: string;
  Company: string;
  Process: string;
  Password: string;
}

// Function to fetch driver information
export const fetchDriverInformation = (payload: Payload) => {
  return axios.post(API_URL, payload).then((response) => response.data);
};

export const submitNote = (payload: any) => {
  return fetch(
    "https://prod-49.westus.logic.azure.com:443/workflows/a91b81f5a921408c8b9ba9f6fd919108/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=DIp18EcWFZYqDSc2l_-KihKRpiyNQaz57SJyNu7t-XQ",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  )
    .then((response) => {
      // Handle response here
      console.log("API response:", response);
    })
    .catch((error) => {
      // Handle error here
      console.error("API error:", error);
    });
};
