import React, { useState, useRef } from "react";
import {
  Box,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Select,
  CircularProgress,
  MenuItem,
  Button,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { submitNote } from "../../api/api";

const NoteAdding: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [note, setNote] = useState("");
  const [user, setUser] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailType, setEmailType] = useState("No");
  const formRef = useRef<HTMLFormElement>(null);

  const users = [
    { name: "Jessie", email: "jessie@hirschbach.com" },
    { name: "Kia", email: "kia.gardner@hirschbach.com" },
    { name: "Austin", email: "austin.stubbs@hirschbach.com" },
    { name: "Shelbe", email: "hols@johnchristner.com" },
    { name: "Dani", email: "dannon.simmons@hirschbach.com" },
    { name: "Tasha", email: "tasha.anderson@hirschbach.com" },
  ];

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setUser(event.target.value);
  };

  const handleEmailTypeChange = (value: string) => {
    setEmailType(value);
  };

  const handleNoteSubmit = () => {
    if (user && note.trim() !== "") {
      setLoading(true);

      const payload = {
        ID: getURLParameter("ID"),
        Process: "Note",
        Password: "AllInToWin2023",
        Company: getURLParameter("Company"),
        Message: note,
        Name: user,
        Email: users.find((u) => u.name === user)?.email || "",
        EmailType: emailType,
      };

      submitNote(payload)
        .then(() => {
          setLoading(false);
          setSuccess(true);
          window.location.reload();
        })
        .catch((error) => {
          setLoading(false);
          console.error("API error:", error);
        });
    } else {
      alert("Both User and Note fields are required!");
    }
  };

  const handleFormReset = () => {
    setUser(null);
    setNote("");
    setEmailType("No");
  };

  const getURLParameter = (name: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name);
  };

  return (
    <MenuItem onClick={onOpen}>
      Add Note
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent
          bg="rgba(255, 255, 255, 0.8)"
          backdropFilter="blur(8px)"
          borderRadius="md"
          mt={100}
        >
          <ModalBody>
            <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
              <Box mb={4}>
                <Select
                  value={user || ""}
                  onChange={handleUserChange}
                  borderRadius="md"
                  bg="white"
                  _hover={{ borderColor: "gray.500" }}
                  _focus={{ borderColor: "blue.500", boxShadow: "outline" }}
                  required
                >
                  <option value="" disabled>
                    Select User
                  </option>
                  {users.map((user) => (
                    <option key={user.name} value={user.name}>
                      {user.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Textarea
                placeholder="Enter your note"
                value={note}
                onChange={handleNoteChange}
                size="lg"
                minH="150px"
                borderRadius="md"
                bg="white"
                _hover={{ borderColor: "gray.500" }}
                _focus={{ borderColor: "blue.500" }}
                required
              />
              <Box
                mt={4}
                p={3}
                bg="white"
                border="1px"
                borderColor="gray.300"
                borderRadius="md"
                display="flex"
                justifyContent="center"
              >
                <RadioGroup
                  onChange={handleEmailTypeChange}
                  value={emailType}
                  defaultValue="No" // Set the default value to "No"
                >
                  <Stack direction="column">
                    <Radio value="No">No Email</Radio>
                    <Radio value="Recruiter">Email the recruiter</Radio>
                    <Radio value="Processor">Email the processor</Radio>
                    <Radio value="Both">
                      Email the recruiter and processor
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            </form>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              variant="ghost"
              onClick={() => {
                onClose();
                handleFormReset();
              }}
            >
              Cancel
            </Button>
            {success ? (
              <Button colorScheme="green" ml={3} disabled>
                Success
              </Button>
            ) : (
              <Button
                colorScheme="blue"
                ml={3}
                onClick={handleNoteSubmit}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress
                    isIndeterminate
                    color="orange.300"
                    size="24px"
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </MenuItem>
  );
};

export default NoteAdding;
