import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  useDisclosure,
  MenuItem,
  Button,
  CircularProgress,
  Text,
  Flex,
} from "@chakra-ui/react";
import { submitNote } from "../../api/api";

const ManagerApprove: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleApprove = () => {
    setLoading(true);

    const payload = {
      ID: getURLParameter("ID"),
      Process: "Approve",
      Password: "AllInToWin2023",
      Company: getURLParameter("Company"),
    };

    submitNote(payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);

        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        console.error("API error:", error);
      });
  };

  const getURLParameter = (name: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name);
  };

  return (
    <MenuItem onClick={onOpen}>
      Approve
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent
          bg="rgba(255, 255, 255, 0.8)"
          backdropFilter="blur(8px)"
          borderRadius="md"
          mt={500}
        >
          <ModalFooter>
            <Flex direction="column" alignItems="center">
              <Text mb={4}>Are you sure you want to approve this driver?</Text>
              <Flex>
                <Button variant="ghost" onClick={onClose} mr={2}>
                  Cancel
                </Button>
                {success ? (
                  <Button colorScheme="green" disabled>
                    Success
                  </Button>
                ) : (
                  <Button
                    colorScheme="blue"
                    onClick={handleApprove}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress
                        isIndeterminate
                        color="orange.300"
                        size="24px"
                      />
                    ) : (
                      "Approve"
                    )}
                  </Button>
                )}
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </MenuItem>
  );
};

export default ManagerApprove;
