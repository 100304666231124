import React from "react";
import {
  Box,
  Text,
  VStack,
  Heading,
  List,
  ListItem,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";

// Define the props for the Simple component
interface DriverInformationProps {
  driverinformation: {
    First: string;
    Last: string;
    Recruiter: string;
    Company: string;
    Location: string;
    Date: string;
    Division: string;
    CC: string;
    Approval: boolean;
  };
}

// Simple component
const Simple: React.FC<DriverInformationProps> = ({ driverinformation }) => {
  const {
    First,
    Last,
    Recruiter,
    Company,
    Location,
    Date,
    Division,
    CC,
    Approval,
  } = driverinformation;

  const goldColor = useColorModeValue("yellow.500", "yellow.300");
  const blueColor = useColorModeValue("blue.500", "blue.300");
  const dividerColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Box width="100%">
      <Box
        bg="white"
        p={4}
        borderRadius="md"
        boxShadow="md"
        maxWidth="100%"
        width="100%"
        margin="auto"
      >
        <VStack spacing={2} align="start">
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "xl", sm: "3xl", lg: "4xl" }}
          >
            {First} {Last} - {Approval === true ? "Approved" : "Not Approved"}
          </Heading>
          <Text
            color={blueColor}
            fontWeight={300}
            fontSize={{ base: "lg", lg: "2xl" }}
          >
            {Recruiter}
          </Text>
        </VStack>
        <Divider borderColor={dividerColor} mt={4} mb={4} />
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color={goldColor}
          fontWeight={"500"}
          textTransform={"uppercase"}
          mt={4}
        >
          Information
        </Text>
        <List spacing={2} w="100%">
          <ListItem>
            <Text as={"span"} fontWeight={"bold"}>
              Company:
            </Text>{" "}
            {Company}
          </ListItem>
          <ListItem>
            <Text as={"span"} fontWeight={"bold"}>
              Orientation Location:
            </Text>{" "}
            {Location}
          </ListItem>
          <ListItem>
            <Text as={"span"} fontWeight={"bold"}>
              Orientation Date:
            </Text>{" "}
            {Date}
          </ListItem>
          <ListItem>
            <Text as={"span"} fontWeight={"bold"}>
              Division:
            </Text>{" "}
            {Division}
          </ListItem>
          <ListItem>
            <Text as={"span"} fontWeight={"bold"}>
              Cost Center:
            </Text>{" "}
            {CC}
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Simple;
