import React from "react";
import { Center, Box, VStack, Flex, CSSReset } from "@chakra-ui/react";
import Simple from "./DriverInformation";
import NoteTable from "./NoteTable";
import DropdownMenu from "./Menu";

interface DriverInformation {
  First: string;
  Last: string;
  Date: string;
  Location: string;
  Division: string;
  CC: string;
  Company: string;
  Recruiter: string;
  Message: string;
  Approval: boolean;
}

interface UserNote {
  Name: string;
  Message: string;
  Time: string;
}

interface GlobalBackgroundProps {
  driverinformation: DriverInformation[];
  UsersNote: UserNote[];
}

const ParamPage: React.FC<GlobalBackgroundProps> = ({
  driverinformation,
  UsersNote,
}) => {
  if (!driverinformation || driverinformation.length === 0) {
    return null;
  }

  const driverInfo = driverinformation[0];

  return (
    <Center py={6}>
      <CSSReset />
      <Box width="100%" maxWidth="1200px">
        <Flex position="relative" align="center" justify="center" mx="auto">
          <Box
            bg="white"
            boxShadow="lg"
            rounded="md"
            overflow="hidden"
            p={{ base: 4, md: 6 }}
            h="85vh"
            maxHeight="80%"
            style={{ overflowY: "auto", margin: "10px" }}
            position="relative"
            width="100%"
          >
            <VStack spacing={4} align="stretch" h="100%">
              <Simple driverinformation={driverInfo} />
              <Box>
                <NoteTable notes={UsersNote} />
              </Box>
            </VStack>
          </Box>
          <Box position="fixed" bottom="1rem" right="auto">
            <DropdownMenu />
          </Box>
        </Flex>
      </Box>
    </Center>
  );
};

export default ParamPage;
