import React from "react";
import { Box, Stack } from "@chakra-ui/react";

interface UserNote {
  Name: string;
  Message: string;
  Time: string;
}

interface NoteTableProps {
  notes: UserNote[];
}

const NoteTable: React.FC<NoteTableProps> = ({ notes }) => {
  return (
    <Stack spacing={4}>
      {notes.map((note, index) => (
        <Box
          key={index}
          shadow="md"
          rounded="md"
          p={4}
          borderWidth={1}
          borderColor="gray.200"
        >
          <strong>Name: </strong> {note.Name} <br />
          <strong>Time: </strong> {note.Time} <br />
          <strong>Message: </strong> {note.Message}
        </Box>
      ))}
    </Stack>
  );
};

export default NoteTable;
